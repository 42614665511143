var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit and bootstrap-timepicker contributors
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
(function ($, window, document) {
  'use strict'; // TIMEPICKER PUBLIC CLASS DEFINITION

  var Timepicker = function (element, options) {
    (this || _global).widget = "";
    (this || _global).$element = $(element);
    (this || _global).defaultTime = options.defaultTime;
    (this || _global).disableFocus = options.disableFocus;
    (this || _global).disableMousewheel = options.disableMousewheel;
    (this || _global).isOpen = options.isOpen;
    (this || _global).minuteStep = options.minuteStep;
    (this || _global).modalBackdrop = options.modalBackdrop;
    (this || _global).orientation = options.orientation;
    (this || _global).secondStep = options.secondStep;
    (this || _global).snapToStep = options.snapToStep;
    (this || _global).showInputs = options.showInputs;
    (this || _global).showMeridian = options.showMeridian;
    (this || _global).showSeconds = options.showSeconds;
    (this || _global).template = options.template;
    (this || _global).appendWidgetTo = options.appendWidgetTo;
    (this || _global).showWidgetOnAddonClick = options.showWidgetOnAddonClick;
    (this || _global).icons = options.icons;
    (this || _global).maxHours = options.maxHours;
    (this || _global).explicitMode = options.explicitMode; // If true 123 = 1:23, 12345 = 1:23:45, else invalid.

    (this || _global).handleDocumentClick = function (e) {
      var self = e.data.scope; // This condition was inspired by bootstrap-datepicker.
      // The element the timepicker is invoked on is the input but it has a sibling for addon/button.

      if (!(self.$element.parent().find(e.target).length || self.$widget.is(e.target) || self.$widget.find(e.target).length)) {
        self.hideWidget();
      }
    };

    this._init();
  };

  Timepicker.prototype = {
    constructor: Timepicker,
    _init: function () {
      var self = this || _global;

      if ((this || _global).showWidgetOnAddonClick && (this || _global).$element.parent().hasClass("input-group") && (this || _global).$element.parent().hasClass("bootstrap-timepicker")) {
        (this || _global).$element.parent(".input-group.bootstrap-timepicker").find(".input-group-addon").on({
          "click.timepicker": $.proxy((this || _global).showWidget, this || _global)
        });

        (this || _global).$element.on({
          "focus.timepicker": $.proxy((this || _global).highlightUnit, this || _global),
          "click.timepicker": $.proxy((this || _global).highlightUnit, this || _global),
          "keydown.timepicker": $.proxy((this || _global).elementKeydown, this || _global),
          "blur.timepicker": $.proxy((this || _global).blurElement, this || _global),
          "mousewheel.timepicker DOMMouseScroll.timepicker": $.proxy((this || _global).mousewheel, this || _global)
        });
      } else {
        if ((this || _global).template) {
          (this || _global).$element.on({
            "focus.timepicker": $.proxy((this || _global).showWidget, this || _global),
            "click.timepicker": $.proxy((this || _global).showWidget, this || _global),
            "blur.timepicker": $.proxy((this || _global).blurElement, this || _global),
            "mousewheel.timepicker DOMMouseScroll.timepicker": $.proxy((this || _global).mousewheel, this || _global)
          });
        } else {
          (this || _global).$element.on({
            "focus.timepicker": $.proxy((this || _global).highlightUnit, this || _global),
            "click.timepicker": $.proxy((this || _global).highlightUnit, this || _global),
            "keydown.timepicker": $.proxy((this || _global).elementKeydown, this || _global),
            "blur.timepicker": $.proxy((this || _global).blurElement, this || _global),
            "mousewheel.timepicker DOMMouseScroll.timepicker": $.proxy((this || _global).mousewheel, this || _global)
          });
        }
      }

      if ((this || _global).template !== false) {
        (this || _global).$widget = $(this.getTemplate()).on("click", $.proxy((this || _global).widgetClick, this || _global));
      } else {
        (this || _global).$widget = false;
      }

      if ((this || _global).showInputs && (this || _global).$widget !== false) {
        (this || _global).$widget.find("input").each(function () {
          $(this || _global).on({
            "click.timepicker": function () {
              $(this || _global).select();
            },
            "keydown.timepicker": $.proxy(self.widgetKeydown, self),
            "keyup.timepicker": $.proxy(self.widgetKeyup, self)
          });
        });
      }

      this.setDefaultTime((this || _global).defaultTime);
    },
    blurElement: function () {
      (this || _global).highlightedUnit = null;
      this.updateFromElementVal();
    },
    clear: function () {
      (this || _global).hour = "";
      (this || _global).minute = "";
      (this || _global).second = "";
      (this || _global).meridian = "";

      (this || _global).$element.val("");
    },
    decrementHour: function () {
      if ((this || _global).showMeridian) {
        if ((this || _global).hour === 1) {
          (this || _global).hour = 12;
        } else if ((this || _global).hour === 12) {
          (this || _global).hour--;
          return this.toggleMeridian();
        } else if ((this || _global).hour === 0) {
          (this || _global).hour = 11;
          return this.toggleMeridian();
        } else {
          (this || _global).hour--;
        }
      } else {
        if ((this || _global).hour <= 0) {
          (this || _global).hour = (this || _global).maxHours - 1;
        } else {
          (this || _global).hour--;
        }
      }
    },
    decrementMinute: function (step) {
      var newVal;

      if (step) {
        newVal = (this || _global).minute - step;
      } else {
        newVal = (this || _global).minute - (this || _global).minuteStep;
      }

      if (newVal < 0) {
        this.decrementHour();
        (this || _global).minute = newVal + 60;
      } else {
        (this || _global).minute = newVal;
      }
    },
    decrementSecond: function () {
      var newVal = (this || _global).second - (this || _global).secondStep;

      if (newVal < 0) {
        this.decrementMinute(true);
        (this || _global).second = newVal + 60;
      } else {
        (this || _global).second = newVal;
      }
    },
    elementKeydown: function (e) {
      switch (e.which) {
        case 9:
          //tab
          if (e.shiftKey) {
            if ((this || _global).highlightedUnit === "hour") {
              this.hideWidget();
              break;
            }

            this.highlightPrevUnit();
          } else if ((this || _global).showMeridian && (this || _global).highlightedUnit === "meridian" || (this || _global).showSeconds && (this || _global).highlightedUnit === "second" || !(this || _global).showMeridian && !(this || _global).showSeconds && (this || _global).highlightedUnit === "minute") {
            this.hideWidget();
            break;
          } else {
            this.highlightNextUnit();
          }

          e.preventDefault();
          this.updateFromElementVal();
          break;

        case 27:
          // escape
          this.updateFromElementVal();
          break;

        case 37:
          // left arrow
          e.preventDefault();
          this.highlightPrevUnit();
          this.updateFromElementVal();
          break;

        case 38:
          // up arrow
          e.preventDefault();

          switch ((this || _global).highlightedUnit) {
            case "hour":
              this.incrementHour();
              this.highlightHour();
              break;

            case "minute":
              this.incrementMinute();
              this.highlightMinute();
              break;

            case "second":
              this.incrementSecond();
              this.highlightSecond();
              break;

            case "meridian":
              this.toggleMeridian();
              this.highlightMeridian();
              break;
          }

          this.update();
          break;

        case 39:
          // right arrow
          e.preventDefault();
          this.highlightNextUnit();
          this.updateFromElementVal();
          break;

        case 40:
          // down arrow
          e.preventDefault();

          switch ((this || _global).highlightedUnit) {
            case "hour":
              this.decrementHour();
              this.highlightHour();
              break;

            case "minute":
              this.decrementMinute();
              this.highlightMinute();
              break;

            case "second":
              this.decrementSecond();
              this.highlightSecond();
              break;

            case "meridian":
              this.toggleMeridian();
              this.highlightMeridian();
              break;
          }

          this.update();
          break;
      }
    },
    getCursorPosition: function () {
      var input = (this || _global).$element.get(0);

      if ("selectionStart" in input) {
        // Standard-compliant browsers
        return input.selectionStart;
      } else if (document.selection) {
        // IE fix
        input.focus();
        var sel = document.selection.createRange(),
            selLen = document.selection.createRange().text.length;
        sel.moveStart("character", -input.value.length);
        return sel.text.length - selLen;
      }
    },
    getTemplate: function () {
      var template, hourTemplate, minuteTemplate, secondTemplate, meridianTemplate, templateContent;

      if ((this || _global).showInputs) {
        hourTemplate = "<input type=\"text\" class=\"bootstrap-timepicker-hour\" maxlength=\"2\"/>";
        minuteTemplate = "<input type=\"text\" class=\"bootstrap-timepicker-minute\" maxlength=\"2\"/>";
        secondTemplate = "<input type=\"text\" class=\"bootstrap-timepicker-second\" maxlength=\"2\"/>";
        meridianTemplate = "<input type=\"text\" class=\"bootstrap-timepicker-meridian\" maxlength=\"2\"/>";
      } else {
        hourTemplate = "<span class=\"bootstrap-timepicker-hour\"></span>";
        minuteTemplate = "<span class=\"bootstrap-timepicker-minute\"></span>";
        secondTemplate = "<span class=\"bootstrap-timepicker-second\"></span>";
        meridianTemplate = "<span class=\"bootstrap-timepicker-meridian\"></span>";
      }

      templateContent = "<table>" + "<tr>" + "<td><a href=\"#\" data-action=\"incrementHour\"><span class=\"" + (this || _global).icons.up + "\"></span></a></td>" + "<td class=\"separator\">&nbsp;</td>" + "<td><a href=\"#\" data-action=\"incrementMinute\"><span class=\"" + (this || _global).icons.up + "\"></span></a></td>" + ((this || _global).showSeconds ? "<td class=\"separator\">&nbsp;</td>" + "<td><a href=\"#\" data-action=\"incrementSecond\"><span class=\"" + (this || _global).icons.up + "\"></span></a></td>" : "") + ((this || _global).showMeridian ? "<td class=\"separator\">&nbsp;</td>" + "<td class=\"meridian-column\"><a href=\"#\" data-action=\"toggleMeridian\"><span class=\"" + (this || _global).icons.up + "\"></span></a></td>" : "") + "</tr>" + "<tr>" + "<td>" + hourTemplate + "</td> " + "<td class=\"separator\">:</td>" + "<td>" + minuteTemplate + "</td> " + ((this || _global).showSeconds ? "<td class=\"separator\">:</td>" + "<td>" + secondTemplate + "</td>" : "") + ((this || _global).showMeridian ? "<td class=\"separator\">&nbsp;</td>" + "<td>" + meridianTemplate + "</td>" : "") + "</tr>" + "<tr>" + "<td><a href=\"#\" data-action=\"decrementHour\"><span class=\"" + (this || _global).icons.down + "\"></span></a></td>" + "<td class=\"separator\"></td>" + "<td><a href=\"#\" data-action=\"decrementMinute\"><span class=\"" + (this || _global).icons.down + "\"></span></a></td>" + ((this || _global).showSeconds ? "<td class=\"separator\">&nbsp;</td>" + "<td><a href=\"#\" data-action=\"decrementSecond\"><span class=\"" + (this || _global).icons.down + "\"></span></a></td>" : "") + ((this || _global).showMeridian ? "<td class=\"separator\">&nbsp;</td>" + "<td><a href=\"#\" data-action=\"toggleMeridian\"><span class=\"" + (this || _global).icons.down + "\"></span></a></td>" : "") + "</tr>" + "</table>";

      switch ((this || _global).template) {
        case "modal":
          template = "<div class=\"bootstrap-timepicker-widget modal hide fade in\" data-backdrop=\"" + ((this || _global).modalBackdrop ? "true" : "false") + "\">" + "<div class=\"modal-header\">" + "<a href=\"#\" class=\"close\" data-dismiss=\"modal\">&times;</a>" + "<h3>Pick a Time</h3>" + "</div>" + "<div class=\"modal-content\">" + templateContent + "</div>" + "<div class=\"modal-footer\">" + "<a href=\"#\" class=\"btn btn-primary\" data-dismiss=\"modal\">OK</a>" + "</div>" + "</div>";
          break;

        case "dropdown":
          template = "<div class=\"bootstrap-timepicker-widget dropdown-menu\">" + templateContent + "</div>";
          break;
      }

      return template;
    },
    getTime: function () {
      if ((this || _global).hour === "") {
        return "";
      }

      return (this || _global).hour + ":" + ((this || _global).minute.toString().length === 1 ? "0" + (this || _global).minute : (this || _global).minute) + ((this || _global).showSeconds ? ":" + ((this || _global).second.toString().length === 1 ? "0" + (this || _global).second : (this || _global).second) : "") + ((this || _global).showMeridian ? " " + (this || _global).meridian : "");
    },
    hideWidget: function () {
      if ((this || _global).isOpen === false) {
        return;
      }

      (this || _global).$element.trigger({
        "type": "hide.timepicker",
        "time": {
          "value": this.getTime(),
          "hours": (this || _global).hour,
          "minutes": (this || _global).minute,
          "seconds": (this || _global).second,
          "meridian": (this || _global).meridian
        }
      });

      if ((this || _global).template === "modal" && (this || _global).$widget.modal) {
        (this || _global).$widget.modal("hide");
      } else {
        (this || _global).$widget.removeClass("open");
      }

      $(document).off("mousedown.timepicker, touchend.timepicker", (this || _global).handleDocumentClick);
      (this || _global).isOpen = false; // show/hide approach taken by datepicker

      (this || _global).$widget.detach();
    },
    highlightUnit: function () {
      (this || _global).position = this.getCursorPosition();

      if ((this || _global).position >= 0 && (this || _global).position <= 2) {
        this.highlightHour();
      } else if ((this || _global).position >= 3 && (this || _global).position <= 5) {
        this.highlightMinute();
      } else if ((this || _global).position >= 6 && (this || _global).position <= 8) {
        if ((this || _global).showSeconds) {
          this.highlightSecond();
        } else {
          this.highlightMeridian();
        }
      } else if ((this || _global).position >= 9 && (this || _global).position <= 11) {
        this.highlightMeridian();
      }
    },
    highlightNextUnit: function () {
      switch ((this || _global).highlightedUnit) {
        case "hour":
          this.highlightMinute();
          break;

        case "minute":
          if ((this || _global).showSeconds) {
            this.highlightSecond();
          } else if ((this || _global).showMeridian) {
            this.highlightMeridian();
          } else {
            this.highlightHour();
          }

          break;

        case "second":
          if ((this || _global).showMeridian) {
            this.highlightMeridian();
          } else {
            this.highlightHour();
          }

          break;

        case "meridian":
          this.highlightHour();
          break;
      }
    },
    highlightPrevUnit: function () {
      switch ((this || _global).highlightedUnit) {
        case "hour":
          if ((this || _global).showMeridian) {
            this.highlightMeridian();
          } else if ((this || _global).showSeconds) {
            this.highlightSecond();
          } else {
            this.highlightMinute();
          }

          break;

        case "minute":
          this.highlightHour();
          break;

        case "second":
          this.highlightMinute();
          break;

        case "meridian":
          if ((this || _global).showSeconds) {
            this.highlightSecond();
          } else {
            this.highlightMinute();
          }

          break;
      }
    },
    highlightHour: function () {
      var $element = (this || _global).$element.get(0),
          self = this || _global;

      (this || _global).highlightedUnit = "hour";

      if ($element.setSelectionRange) {
        setTimeout(function () {
          if (self.hour < 10) {
            $element.setSelectionRange(0, 1);
          } else {
            $element.setSelectionRange(0, 2);
          }
        }, 0);
      }
    },
    highlightMinute: function () {
      var $element = (this || _global).$element.get(0),
          self = this || _global;

      (this || _global).highlightedUnit = "minute";

      if ($element.setSelectionRange) {
        setTimeout(function () {
          if (self.hour < 10) {
            $element.setSelectionRange(2, 4);
          } else {
            $element.setSelectionRange(3, 5);
          }
        }, 0);
      }
    },
    highlightSecond: function () {
      var $element = (this || _global).$element.get(0),
          self = this || _global;

      (this || _global).highlightedUnit = "second";

      if ($element.setSelectionRange) {
        setTimeout(function () {
          if (self.hour < 10) {
            $element.setSelectionRange(5, 7);
          } else {
            $element.setSelectionRange(6, 8);
          }
        }, 0);
      }
    },
    highlightMeridian: function () {
      var $element = (this || _global).$element.get(0),
          self = this || _global;

      (this || _global).highlightedUnit = "meridian";

      if ($element.setSelectionRange) {
        if ((this || _global).showSeconds) {
          setTimeout(function () {
            if (self.hour < 10) {
              $element.setSelectionRange(8, 10);
            } else {
              $element.setSelectionRange(9, 11);
            }
          }, 0);
        } else {
          setTimeout(function () {
            if (self.hour < 10) {
              $element.setSelectionRange(5, 7);
            } else {
              $element.setSelectionRange(6, 8);
            }
          }, 0);
        }
      }
    },
    incrementHour: function () {
      if ((this || _global).showMeridian) {
        if ((this || _global).hour === 11) {
          (this || _global).hour++;
          return this.toggleMeridian();
        } else if ((this || _global).hour === 12) {
          (this || _global).hour = 0;
        }
      }

      if ((this || _global).hour === (this || _global).maxHours - 1) {
        (this || _global).hour = 0;
        return;
      }

      (this || _global).hour++;
    },
    incrementMinute: function (step) {
      var newVal;

      if (step) {
        newVal = (this || _global).minute + step;
      } else {
        newVal = (this || _global).minute + (this || _global).minuteStep - (this || _global).minute % (this || _global).minuteStep;
      }

      if (newVal > 59) {
        this.incrementHour();
        (this || _global).minute = newVal - 60;
      } else {
        (this || _global).minute = newVal;
      }
    },
    incrementSecond: function () {
      var newVal = (this || _global).second + (this || _global).secondStep - (this || _global).second % (this || _global).secondStep;

      if (newVal > 59) {
        this.incrementMinute(true);
        (this || _global).second = newVal - 60;
      } else {
        (this || _global).second = newVal;
      }
    },
    mousewheel: function (e) {
      if ((this || _global).disableMousewheel) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      var delta = e.originalEvent.wheelDelta || -e.originalEvent.detail,
          scrollTo = null;

      if (e.type === "mousewheel") {
        scrollTo = e.originalEvent.wheelDelta * -1;
      } else if (e.type === "DOMMouseScroll") {
        scrollTo = 40 * e.originalEvent.detail;
      }

      if (scrollTo) {
        e.preventDefault();
        $(this || _global).scrollTop(scrollTo + $(this || _global).scrollTop());
      }

      switch ((this || _global).highlightedUnit) {
        case "minute":
          if (delta > 0) {
            this.incrementMinute();
          } else {
            this.decrementMinute();
          }

          this.highlightMinute();
          break;

        case "second":
          if (delta > 0) {
            this.incrementSecond();
          } else {
            this.decrementSecond();
          }

          this.highlightSecond();
          break;

        case "meridian":
          this.toggleMeridian();
          this.highlightMeridian();
          break;

        default:
          if (delta > 0) {
            this.incrementHour();
          } else {
            this.decrementHour();
          }

          this.highlightHour();
          break;
      }

      return false;
    },

    /**
     * Given a segment value like 43, will round and snap the segment
     * to the nearest "step", like 45 if step is 15. Segment will
     * "overflow" to 0 if it's larger than 59 or would otherwise
     * round up to 60.
     */
    changeToNearestStep: function (segment, step) {
      if (segment % step === 0) {
        return segment;
      }

      if (Math.round(segment % step / step)) {
        return (segment + (step - segment % step)) % 60;
      } else {
        return segment - segment % step;
      }
    },
    // This method was adapted from bootstrap-datepicker.
    place: function () {
      if ((this || _global).isInline) {
        return;
      }

      var widgetWidth = (this || _global).$widget.outerWidth(),
          widgetHeight = (this || _global).$widget.outerHeight(),
          visualPadding = 10,
          windowWidth = $(window).width(),
          windowHeight = $(window).height(),
          scrollTop = $(window).scrollTop();

      var zIndex = parseInt((this || _global).$element.parents().filter(function () {
        return $(this || _global).css("z-index") !== "auto";
      }).first().css("z-index"), 10) + 10;
      var offset = (this || _global).component ? (this || _global).component.parent().offset() : (this || _global).$element.offset();
      var height = (this || _global).component ? (this || _global).component.outerHeight(true) : (this || _global).$element.outerHeight(false);
      var width = (this || _global).component ? (this || _global).component.outerWidth(true) : (this || _global).$element.outerWidth(false);
      var left = offset.left,
          top = offset.top;

      (this || _global).$widget.removeClass("timepicker-orient-top timepicker-orient-bottom timepicker-orient-right timepicker-orient-left");

      if ((this || _global).orientation.x !== "auto") {
        (this || _global).$widget.addClass("timepicker-orient-" + (this || _global).orientation.x);

        if ((this || _global).orientation.x === "right") {
          left -= widgetWidth - width;
        }
      } else {
        // auto x orientation is best-placement: if it crosses a window edge, fudge it sideways
        // Default to left
        (this || _global).$widget.addClass("timepicker-orient-left");

        if (offset.left < 0) {
          left -= offset.left - visualPadding;
        } else if (offset.left + widgetWidth > windowWidth) {
          left = windowWidth - widgetWidth - visualPadding;
        }
      } // auto y orientation is best-situation: top or bottom, no fudging, decision based on which shows more of the widget


      var yorient = (this || _global).orientation.y,
          topOverflow,
          bottomOverflow;

      if (yorient === "auto") {
        topOverflow = -scrollTop + offset.top - widgetHeight;
        bottomOverflow = scrollTop + windowHeight - (offset.top + height + widgetHeight);

        if (Math.max(topOverflow, bottomOverflow) === bottomOverflow) {
          yorient = "top";
        } else {
          yorient = "bottom";
        }
      }

      (this || _global).$widget.addClass("timepicker-orient-" + yorient);

      if (yorient === "top") {
        top += height;
      } else {
        top -= widgetHeight + parseInt((this || _global).$widget.css("padding-top"), 10);
      }

      (this || _global).$widget.css({
        top: top,
        left: left,
        zIndex: zIndex
      });
    },
    remove: function () {
      $("document").off(".timepicker");

      if ((this || _global).$widget) {
        (this || _global).$widget.remove();
      }

      delete (this || _global).$element.data().timepicker;
    },
    setDefaultTime: function (defaultTime) {
      if (!(this || _global).$element.val()) {
        if (defaultTime === "current") {
          var dTime = new Date(),
              hours = dTime.getHours(),
              minutes = dTime.getMinutes(),
              seconds = dTime.getSeconds(),
              meridian = "AM";

          if (seconds !== 0) {
            seconds = Math.ceil(dTime.getSeconds() / (this || _global).secondStep) * (this || _global).secondStep;

            if (seconds === 60) {
              minutes += 1;
              seconds = 0;
            }
          }

          if (minutes !== 0) {
            minutes = Math.ceil(dTime.getMinutes() / (this || _global).minuteStep) * (this || _global).minuteStep;

            if (minutes === 60) {
              hours += 1;
              minutes = 0;
            }
          }

          if ((this || _global).showMeridian) {
            if (hours === 0) {
              hours = 12;
            } else if (hours >= 12) {
              if (hours > 12) {
                hours = hours - 12;
              }

              meridian = "PM";
            } else {
              meridian = "AM";
            }
          }

          (this || _global).hour = hours;
          (this || _global).minute = minutes;
          (this || _global).second = seconds;
          (this || _global).meridian = meridian;
          this.update();
        } else if (defaultTime === false) {
          (this || _global).hour = 0;
          (this || _global).minute = 0;
          (this || _global).second = 0;
          (this || _global).meridian = "AM";
        } else {
          this.setTime(defaultTime);
        }
      } else {
        this.updateFromElementVal();
      }
    },
    setTime: function (time, ignoreWidget) {
      if (!time) {
        this.clear();
        return;
      }

      var timeMode, timeArray, hour, minute, second, meridian;

      if (typeof time === "object" && time.getMonth) {
        // this is a date object
        hour = time.getHours();
        minute = time.getMinutes();
        second = time.getSeconds();

        if ((this || _global).showMeridian) {
          meridian = "AM";

          if (hour > 12) {
            meridian = "PM";
            hour = hour % 12;
          }

          if (hour === 12) {
            meridian = "PM";
          }
        }
      } else {
        timeMode = (/a/i.test(time) ? 1 : 0) + (/p/i.test(time) ? 2 : 0); // 0 = none, 1 = AM, 2 = PM, 3 = BOTH.

        if (timeMode > 2) {
          // If both are present, fail.
          this.clear();
          return;
        }

        timeArray = time.replace(/[^0-9\:]/g, "").split(":");
        hour = timeArray[0] ? timeArray[0].toString() : timeArray.toString();

        if ((this || _global).explicitMode && hour.length > 2 && hour.length % 2 !== 0) {
          this.clear();
          return;
        }

        minute = timeArray[1] ? timeArray[1].toString() : "";
        second = timeArray[2] ? timeArray[2].toString() : ""; // adaptive time parsing

        if (hour.length > 4) {
          second = hour.slice(-2);
          hour = hour.slice(0, -2);
        }

        if (hour.length > 2) {
          minute = hour.slice(-2);
          hour = hour.slice(0, -2);
        }

        if (minute.length > 2) {
          second = minute.slice(-2);
          minute = minute.slice(0, -2);
        }

        hour = parseInt(hour, 10);
        minute = parseInt(minute, 10);
        second = parseInt(second, 10);

        if (isNaN(hour)) {
          hour = 0;
        }

        if (isNaN(minute)) {
          minute = 0;
        }

        if (isNaN(second)) {
          second = 0;
        } // Adjust the time based upon unit boundary.
        // NOTE: Negatives will never occur due to time.replace() above.


        if (second > 59) {
          second = 59;
        }

        if (minute > 59) {
          minute = 59;
        }

        if (hour >= (this || _global).maxHours) {
          // No day/date handling.
          hour = (this || _global).maxHours - 1;
        }

        if ((this || _global).showMeridian) {
          if (hour > 12) {
            // Force PM.
            timeMode = 2;
            hour -= 12;
          }

          if (!timeMode) {
            timeMode = 1;
          }

          if (hour === 0) {
            hour = 12; // AM or PM, reset to 12.  0 AM = 12 AM.  0 PM = 12 PM, etc.
          }

          meridian = timeMode === 1 ? "AM" : "PM";
        } else if (hour < 12 && timeMode === 2) {
          hour += 12;
        } else {
          if (hour >= (this || _global).maxHours) {
            hour = (this || _global).maxHours - 1;
          } else if (hour < 0 || hour === 12 && timeMode === 1) {
            hour = 0;
          }
        }
      }

      (this || _global).hour = hour;

      if ((this || _global).snapToStep) {
        (this || _global).minute = this.changeToNearestStep(minute, (this || _global).minuteStep);
        (this || _global).second = this.changeToNearestStep(second, (this || _global).secondStep);
      } else {
        (this || _global).minute = minute;
        (this || _global).second = second;
      }

      (this || _global).meridian = meridian;
      this.update(ignoreWidget);
    },
    showWidget: function () {
      if ((this || _global).isOpen) {
        return;
      }

      if ((this || _global).$element.is(":disabled")) {
        return;
      } // show/hide approach taken by datepicker


      (this || _global).$widget.appendTo((this || _global).appendWidgetTo);

      $(document).on("mousedown.timepicker, touchend.timepicker", {
        scope: this || _global
      }, (this || _global).handleDocumentClick);

      (this || _global).$element.trigger({
        "type": "show.timepicker",
        "time": {
          "value": this.getTime(),
          "hours": (this || _global).hour,
          "minutes": (this || _global).minute,
          "seconds": (this || _global).second,
          "meridian": (this || _global).meridian
        }
      });

      this.place();

      if ((this || _global).disableFocus) {
        (this || _global).$element.blur();
      } // widget shouldn't be empty on open


      if ((this || _global).hour === "") {
        if ((this || _global).defaultTime) {
          this.setDefaultTime((this || _global).defaultTime);
        } else {
          this.setTime("0:0:0");
        }
      }

      if ((this || _global).template === "modal" && (this || _global).$widget.modal) {
        (this || _global).$widget.modal("show").on("hidden", $.proxy((this || _global).hideWidget, this || _global));
      } else {
        if ((this || _global).isOpen === false) {
          (this || _global).$widget.addClass("open");
        }
      }

      (this || _global).isOpen = true;
    },
    toggleMeridian: function () {
      (this || _global).meridian = (this || _global).meridian === "AM" ? "PM" : "AM";
    },
    update: function (ignoreWidget) {
      this.updateElement();

      if (!ignoreWidget) {
        this.updateWidget();
      }

      (this || _global).$element.trigger({
        "type": "changeTime.timepicker",
        "time": {
          "value": this.getTime(),
          "hours": (this || _global).hour,
          "minutes": (this || _global).minute,
          "seconds": (this || _global).second,
          "meridian": (this || _global).meridian
        }
      });
    },
    updateElement: function () {
      (this || _global).$element.val(this.getTime()).change();
    },
    updateFromElementVal: function () {
      this.setTime((this || _global).$element.val());
    },
    updateWidget: function () {
      if ((this || _global).$widget === false) {
        return;
      }

      var hour = (this || _global).hour,
          minute = (this || _global).minute.toString().length === 1 ? "0" + (this || _global).minute : (this || _global).minute,
          second = (this || _global).second.toString().length === 1 ? "0" + (this || _global).second : (this || _global).second;

      if ((this || _global).showInputs) {
        (this || _global).$widget.find("input.bootstrap-timepicker-hour").val(hour);

        (this || _global).$widget.find("input.bootstrap-timepicker-minute").val(minute);

        if ((this || _global).showSeconds) {
          (this || _global).$widget.find("input.bootstrap-timepicker-second").val(second);
        }

        if ((this || _global).showMeridian) {
          (this || _global).$widget.find("input.bootstrap-timepicker-meridian").val((this || _global).meridian);
        }
      } else {
        (this || _global).$widget.find("span.bootstrap-timepicker-hour").text(hour);

        (this || _global).$widget.find("span.bootstrap-timepicker-minute").text(minute);

        if ((this || _global).showSeconds) {
          (this || _global).$widget.find("span.bootstrap-timepicker-second").text(second);
        }

        if ((this || _global).showMeridian) {
          (this || _global).$widget.find("span.bootstrap-timepicker-meridian").text((this || _global).meridian);
        }
      }
    },
    updateFromWidgetInputs: function () {
      if ((this || _global).$widget === false) {
        return;
      }

      var t = (this || _global).$widget.find("input.bootstrap-timepicker-hour").val() + ":" + (this || _global).$widget.find("input.bootstrap-timepicker-minute").val() + ((this || _global).showSeconds ? ":" + (this || _global).$widget.find("input.bootstrap-timepicker-second").val() : "") + ((this || _global).showMeridian ? (this || _global).$widget.find("input.bootstrap-timepicker-meridian").val() : "");
      this.setTime(t, true);
    },
    widgetClick: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var $input = $(e.target),
          action = $input.closest("a").data("action");

      if (action) {
        this[action]();
      }

      this.update();

      if ($input.is("input")) {
        $input.get(0).setSelectionRange(0, 2);
      }
    },
    widgetKeydown: function (e) {
      var $input = $(e.target),
          name = $input.attr("class").replace("bootstrap-timepicker-", "");

      switch (e.which) {
        case 9:
          //tab
          if (e.shiftKey) {
            if (name === "hour") {
              return this.hideWidget();
            }
          } else if ((this || _global).showMeridian && name === "meridian" || (this || _global).showSeconds && name === "second" || !(this || _global).showMeridian && !(this || _global).showSeconds && name === "minute") {
            return this.hideWidget();
          }

          break;

        case 27:
          // escape
          this.hideWidget();
          break;

        case 38:
          // up arrow
          e.preventDefault();

          switch (name) {
            case "hour":
              this.incrementHour();
              break;

            case "minute":
              this.incrementMinute();
              break;

            case "second":
              this.incrementSecond();
              break;

            case "meridian":
              this.toggleMeridian();
              break;
          }

          this.setTime(this.getTime());
          $input.get(0).setSelectionRange(0, 2);
          break;

        case 40:
          // down arrow
          e.preventDefault();

          switch (name) {
            case "hour":
              this.decrementHour();
              break;

            case "minute":
              this.decrementMinute();
              break;

            case "second":
              this.decrementSecond();
              break;

            case "meridian":
              this.toggleMeridian();
              break;
          }

          this.setTime(this.getTime());
          $input.get(0).setSelectionRange(0, 2);
          break;
      }
    },
    widgetKeyup: function (e) {
      if (e.which === 65 || e.which === 77 || e.which === 80 || e.which === 46 || e.which === 8 || e.which >= 48 && e.which <= 57 || e.which >= 96 && e.which <= 105) {
        this.updateFromWidgetInputs();
      }
    }
  }; //TIMEPICKER PLUGIN DEFINITION

  $.fn.timepicker = function (option) {
    var args = Array.apply(null, arguments);
    args.shift();
    return this.each(function () {
      var $this = $(this || _global),
          data = $this.data("timepicker"),
          options = typeof option === "object" && option;

      if (!data) {
        $this.data("timepicker", data = new Timepicker(this || _global, $.extend({}, $.fn.timepicker.defaults, options, $(this || _global).data())));
      }

      if (typeof option === "string") {
        data[option].apply(data, args);
      }
    });
  };

  $.fn.timepicker.defaults = {
    defaultTime: "current",
    disableFocus: false,
    disableMousewheel: false,
    isOpen: false,
    minuteStep: 15,
    modalBackdrop: false,
    orientation: {
      x: "auto",
      y: "auto"
    },
    secondStep: 15,
    snapToStep: false,
    showSeconds: false,
    showInputs: true,
    showMeridian: true,
    template: "dropdown",
    appendWidgetTo: "body",
    showWidgetOnAddonClick: true,
    icons: {
      up: "glyphicon glyphicon-chevron-up",
      down: "glyphicon glyphicon-chevron-down"
    },
    maxHours: 24,
    explicitMode: false
  };
  $.fn.timepicker.Constructor = Timepicker;
  $(document).on("focus.timepicker.data-api click.timepicker.data-api", "[data-provide=\"timepicker\"]", function (e) {
    var $this = $(this || _global);

    if ($this.data("timepicker")) {
      return;
    }

    e.preventDefault(); // component click requires us to explicitly show it

    $this.timepicker();
  });
})(jQuery, window, document);

export default {};